@page {
    margin: 0.5cm;
}

img {
    display: none;
}

.ie-warning {
    display: none;
}

.header {
    display: none;
}

.footer {
    display: none;
}

.nav {
    display: none;
}

.btn {
    display: none;
}

.no-print {
    display: none;
}

.do-print {
    &.do-print-block {
        display: block;
    }
    &.do-print-inline {
        display: inline;;
    }
}

thead {
    display: table-row-group;;
}

tr {
    page-break-inside: avoid !important;
}

.nobreak-print {
    page-break-inside: avoid !important;
}

.force-new-page {
    page-break-after: always !important;
}
